/* Specific styles for Project page */
:root {
  --sea-blue: #3a6d82; 
  --text-dark: #2f4858;
  --text-medium: #556f7a;
  --text-light: #849aa3;
  --paper-color: rgba(248, 246, 240, 0.9); 
  --divider-color: rgba(0, 0, 0, 0.07);
  --link-color: #3a6d82; 
}

/* Base component styles */
.container {
  max-width: 800px; 
  margin: 0 auto; 
  padding: 2.5rem 1.5rem 5rem; 
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem; 
  border-radius: 0.75rem; 
  background-color: var(--paper-color);
  backdrop-filter: blur(12px); 
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.06); 
  margin-bottom: 2rem;
}

.site-title {
  font-family: "Playfair Display", serif;
  font-size: 2rem; 
  font-weight: 500;
  color: var(--sea-blue);
  letter-spacing: -0.01em;
}

.site-title a {
    color: var(--sea-blue);
    text-decoration: none;
    border-bottom: none;
}

.site-title a:hover {
    opacity: 0.9;
    border-bottom: none;
}

nav {
  display: flex;
  gap: 2rem; 
}

nav a {
  color: var(--text-medium);
  text-decoration: none;
  font-weight: 400;
  font-size: 1rem;
  transition: color 0.2s ease;
  padding-bottom: 2px;
  border-bottom: 1px solid transparent;
}

nav a:hover {
  color: var(--sea-blue);
  border-bottom-color: var(--sea-blue);
}

.content {
  background-color: var(--paper-color);
  border-radius: 0.75rem;
  backdrop-filter: blur(12px);
  padding: 2.25rem 2.5rem 2.5rem; 
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.07);
}


/* Project specific styles */
.loading-state,
.error-state {
    text-align: center;
    padding: 3rem 1rem;
    font-size: 1.1rem;
    color: var(--text-medium);
    font-style: italic;
}

.project-content-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 0.5rem 1.5rem;
  margin-bottom: 15px;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid var(--divider-color);
}

.project-title {
  font-family: "Playfair Display", serif;
  font-size: 2.4rem;
  font-weight: 500;
  color: var(--sea-blue);
  line-height: 1.3;
  margin: 0;
  flex: 1 1 auto;
  margin-bottom: 5px;
}

.project-meta-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  color: #6b7280;
  font-size: 0.9em;
  font-style: italic;
  flex-shrink: 0;
  text-align: right;
}

.project-date {
  margin-bottom: 0.25rem;
}

.project-tags {
  display: inline-flex;
  gap: 5px;
}

.project-tag {
  font-size: 0.9em;
}

.project-links {
  display: flex;
  gap: 0.75rem;
  justify-content: flex-end;
}

.project-links a {
  text-decoration: none;
  color: inherit;
  margin-left: 5px;
}

.project-links a:first-of-type {
    margin-left: 0;
}

.project-links a:hover {
    color: var(--link-color);
    border-bottom-color: var(--link-color);
}

.project-image {
  width: 100%;
  height: auto;
  margin: 1.5rem 0 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  margin-bottom: 20px;
}

/* Markdown content styling (shared with Article) */
.markdown-content {
  font-size: 1.05rem;
  line-height: 1.75;
  font-weight: 300;
}

.markdown-content h1, 
.markdown-content h2, 
.markdown-content h3, 
.markdown-content h4, 
.markdown-content h5, 
.markdown-content h6 {
  font-family: "Playfair Display", serif;
  color: var(--sea-blue);
  margin-top: 2.25em;
  margin-bottom: 1em;
  line-height: 1.3;
  font-weight: 500;
}

.markdown-content h1 { font-size: 1.8em; }
.markdown-content h2 { font-size: 1.5em; }
.markdown-content h3 { font-size: 1.3em; }
.markdown-content h4 { font-size: 1.15em; }

.markdown-content p {
  margin-bottom: 1.3em;
}

.markdown-content a {
  color: var(--link-color);
  text-decoration: none;
  border-bottom: 1px solid var(--divider-color);
}

.markdown-content a:hover {
  border-bottom-color: var(--link-color);
}

.markdown-content strong {
  font-weight: 500;
  color: var(--text-dark);
}

.markdown-content em {
  font-style: italic;
}

.markdown-content ul, 
.markdown-content ol {
  margin-left: 1.75em;
  margin-bottom: 1.3em;
}

.markdown-content li {
  margin-bottom: 0.5em;
}

.markdown-content blockquote {
  border-left: 3px solid var(--divider-color);
  padding-left: 1.25em;
  margin: 1.75em 0;
  color: var(--text-light);
  font-style: italic;
}

.markdown-content code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  background-color: rgba(0, 0, 0, 0.04);
  padding: 0.2em 0.4em;
  border-radius: 0.25rem;
  font-size: 0.9em;
}

.markdown-content pre {
  background-color: rgba(0, 0, 0, 0.04);
  padding: 1.25em;
  border-radius: 0.5rem;
  overflow-x: auto;
  margin: 1.5em 0;
}

.markdown-content pre code {
  background-color: transparent;
  padding: 0;
  font-size: 0.9em;
}

.markdown-content hr {
  border: none;
  border-top: 1px solid var(--divider-color);
  margin: 2.5em 0;
}

.markdown-content table {
  width: 100%;
  border-collapse: collapse;
  margin: 1.5em 0;
}

.markdown-content th, 
.markdown-content td {
  border: 1px solid var(--divider-color);
  padding: 0.6em 0.8em;
  text-align: left;
}

.markdown-content th {
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0.02);
}

.markdown-content figure {
  margin: 1.75em 0;
  text-align: center;
}

.markdown-content figure img {
  max-width: 100%;
  height: auto;
  border-radius: 0.5rem;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.09);
}

.markdown-content figcaption {
  font-size: 0.9em;
  color: var(--text-light);
  margin-top: 0.6em;
  font-style: italic;
}

.markdown-content .footnote-ref {
  vertical-align: super;
  font-size: 0.75em;
  margin-left: 0.1em;
}

.markdown-content .text-sm {
  font-size: 0.9em;
}

.markdown-content .text-stone-500 {
  color: var(--text-light);
}

/* Responsive adjustments */
@media (max-width: 900px) {
  .container {
    max-width: 90%;
    padding: 2rem 1.25rem 4rem;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 1.5rem 1rem 3rem;
  }
  header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding: 1.25rem;
  }
  nav {
    width: 100%;
    justify-content: space-around; 
    margin-top: 0.5rem;
  }
  .content {
    padding: 1.75rem;
  }
  .site-title {
    font-size: 1.8rem;
  }
  .project-title {
    font-size: 2rem;
  }
  .project-content-header {
    align-items: baseline;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 1.25rem 0.75rem 3rem;
  }
  .content {
    padding: 1.5rem 1.25rem;
  }
  .site-title {
    font-size: 1.6rem;
  }
  .project-content-header {
    flex-direction: column;
    align-items: flex-start;
    border-bottom: none;
    margin-bottom: 1rem;
    padding-bottom: 0;
  }
  .project-title {
    font-size: 1.8rem;
    margin-bottom: 0.75rem;
  }
  .project-meta-block {
    align-items: flex-start;
    text-align: left;
    margin-top: 0.25rem;
  }
  .project-tags,
  .project-links {
    justify-content: flex-start;
  }
  nav a { font-size: 0.95rem; }
}
