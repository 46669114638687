/* Specific styles for Blog page */
:root {
  --sea-blue: #3a6d82; /* Slightly softer blue */
  --text-dark: #2f4858;
  --text-medium: #556f7a;
  --text-light: #849aa3;
  --paper-color: rgba(248, 246, 240, 0.9); /* Slightly warmer, more opaque */
  --divider-color: rgba(0, 0, 0, 0.07);
  --link-color: #3a6d82; 
  --soft-green-accent: #a2bb8c;
}

/* Component-specific styles */
.container {
  max-width: 800px; /* Wider container */
  margin: 0 auto; /* Centered */
  padding: 2.5rem 1.5rem 5rem; /* Adjusted padding */
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem; /* Slightly more padding */
  border-radius: 0.75rem; /* Slightly more rounded */
  background-color: var(--paper-color);
  backdrop-filter: blur(12px); /* More blur */
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.06); /* Softer shadow */
  margin-bottom: 2rem;
}

.site-title {
  font-family: "Playfair Display", serif;
  font-size: 2rem; /* Slightly larger */
  font-weight: 500;
  color: var(--sea-blue);
  letter-spacing: -0.01em;
}

.site-title a {
    color: var(--sea-blue);
    text-decoration: none;
    border-bottom: none;
}

.site-title a:hover {
    opacity: 0.9;
    border-bottom: none;
}

nav {
  display: flex;
  gap: 2rem; /* More space */
}

nav a {
  color: var(--text-medium);
  text-decoration: none;
  font-weight: 400;
  font-size: 1rem;
  transition: color 0.2s ease;
  padding-bottom: 2px;
  border-bottom: 1px solid transparent;
}

nav a:hover {
  color: var(--sea-blue);
  border-bottom-color: var(--sea-blue);
}

.content {
  background-color: var(--paper-color);
  border-radius: 0.75rem;
  backdrop-filter: blur(12px);
  padding: 2.25rem 2.5rem 2.5rem; /* Adjusted padding */
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.07);
}

section {
  margin-bottom: 2.5rem;
}

section:last-of-type {
  margin-bottom: 0; /* No margin on last section */
}

.page-title {
  font-family: "Playfair Display", serif;
  font-size: 2rem; /* Specific for Blog/Projects list titles */
  font-weight: 500;
  color: var(--sea-blue);
  margin-bottom: 2rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--divider-color);
  letter-spacing: -0.01em;
}

/* Entry/Post list items */
.post {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid var(--divider-color);
}

.post:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.post-title {
  font-family: "Playfair Display", serif;
  font-size: 1.15rem;
  font-weight: 400;
  color: var(--text-dark);
  margin-bottom: 0.4rem;
}

.post-title a {
    color: var(--text-dark);
    border-bottom: none;
}

.post-title a:hover {
    color: var(--link-color);
    border-bottom: none;
}

.post-description {
  padding-left: 0;
  font-size: 1rem;
  line-height: 1.7;
  font-weight: 300;
}

/* Meta info styling */
.post-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 0.4rem;
  gap: 0.5rem 1rem; 
}

.post-meta {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 0.5rem 1rem;
    font-size: 0.9rem;
    color: var(--text-light);
    font-style: italic;
}

.post-date {}

.post-tags {
  display: flex;
  gap: 0.75rem;
}

.post-tag {
  color: var(--text-light);
}

/* Responsive adjustments */
@media (max-width: 900px) {
  .container {
    max-width: 90%;
    padding: 2rem 1.25rem 4rem;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 1.5rem 1rem 3rem;
  }
  header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding: 1.25rem;
  }
  nav {
    width: 100%;
    justify-content: space-around; /* Space out nav items */
    margin-top: 0.5rem;
  }
  .content {
    padding: 1.75rem;
  }
  .site-title, .page-title {
    font-size: 1.8rem;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 1.25rem 0.75rem 3rem;
  }
  .content {
    padding: 1.5rem 1.25rem;
  }
  .site-title, .page-title {
    font-size: 1.6rem;
  }
  .post-title {
    font-size: 1.15rem;
  }
  nav a { font-size: 0.95rem; }
}

.blog-content {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .blog-title {
    font-size: 2.5em;
    margin-bottom: 1em;
    text-align: center;
  }
  
  .blog-post {
    margin-bottom: 2em;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    overflow: hidden;
  }
  
  .blog-post-header {
    display: flex;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .blog-post-header:hover {
    background-color: var(--hover-bg-color);
  }
  
  .blog-post-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 20px;
    border-radius: 4px;
  }
  
  .blog-post-title {
    flex-grow: 1;
    font-size: 1.5em;
    margin: 0;
  }
  
  .blog-post-toggle {
    font-size: 1.5em;
    color: var(--highlight-color);
  }
  
  .blog-post-content {
    padding: 20px;
    border-top: 1px solid var(--border-color);
  }
  
  .read-more {
    display: inline-block;
    margin-top: 10px;
    color: var(--highlight-color);
    text-decoration: none;
    border-bottom: 1px solid var(--highlight-color);
  }
  
  .read-more:hover {
    border-bottom-style: dashed;
  }
  