/* Specific styles for Projects page */
:root {
  --sea-blue: #3a6d82; /* Slightly softer blue */
  --text-dark: #2f4858;
  --text-medium: #556f7a;
  --text-light: #849aa3;
  --paper-color: rgba(248, 246, 240, 0.9); /* Slightly warmer, more opaque */
  --divider-color: rgba(0, 0, 0, 0.07);
  --link-color: #3a6d82; 
  --soft-green-accent: #a2bb8c;
}

/* Component-specific styles */
.container {
  max-width: 800px; /* Wider container */
  margin: 0 auto; /* Centered */
  padding: 2.5rem 1.5rem 5rem; /* Adjusted padding */
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem; /* Slightly more padding */
  border-radius: 0.75rem; /* Slightly more rounded */
  background-color: var(--paper-color);
  backdrop-filter: blur(12px); /* More blur */
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.06); /* Softer shadow */
  margin-bottom: 2rem;
}

.site-title {
  font-family: "Playfair Display", serif;
  font-size: 2rem; /* Slightly larger */
  font-weight: 500;
  color: var(--sea-blue);
  letter-spacing: -0.01em;
}

.site-title a {
    color: var(--sea-blue);
    text-decoration: none;
    border-bottom: none;
}

.site-title a:hover {
    opacity: 0.9;
    border-bottom: none;
}

nav {
  display: flex;
  gap: 2rem; /* More space */
}

nav a {
  color: var(--text-medium);
  text-decoration: none;
  font-weight: 400;
  font-size: 1rem;
  transition: color 0.2s ease;
  padding-bottom: 2px;
  border-bottom: 1px solid transparent;
}

nav a:hover {
  color: var(--sea-blue);
  border-bottom-color: var(--sea-blue);
}

.content {
  background-color: var(--paper-color);
  border-radius: 0.75rem;
  backdrop-filter: blur(12px);
  padding: 2.25rem 2.5rem 2.5rem; /* Adjusted padding */
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.07);
}

section {
  margin-bottom: 2.5rem;
}

section:last-of-type {
  margin-bottom: 0; /* No margin on last section */
}

.page-title {
  font-family: "Playfair Display", serif;
  font-size: 2rem; /* Specific for Blog/Projects list titles */
  font-weight: 500;
  color: var(--sea-blue);
  margin-bottom: 2rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--divider-color);
  letter-spacing: -0.01em;
}


/* Project entry styles synchronized with Blog styles */
.project-entry {
  margin-bottom: 2rem; /* Match blog spacing */
  padding-bottom: 2rem; /* Match blog spacing */
  border-bottom: 1px solid var(--divider-color);
}

.project-entry:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.project-header { /* Contains Title + Links */
  display: flex;
  justify-content: space-between; 
  align-items: baseline; 
  margin-bottom: 0.4rem; /* Match post-header margin */
  gap: 1rem; 
}

.project-title { /* Style like post-title */
  font-family: "Playfair Display", serif; 
  font-size: 1.3rem; /* Match post-title size */
  font-weight: 400;  /* Match post-title weight */
  color: var(--text-dark); /* Match post-title color */
  margin: 0; 
  flex-grow: 1; 
}

.project-title a {
    color: var(--text-dark); /* Match post-title link color */
    text-decoration: none;
    border-bottom: none;
}

.project-title a:hover {
    color: var(--link-color); /* Match post-title link hover */
    border-bottom: none;
    opacity: 1; /* Use opacity 1 like blog */
}

.project-links { /* Keep specific project link styling */
  display: flex;
  align-items: baseline;
  gap: 0.75rem;
  font-size: 0.9rem;
  flex-shrink: 0; 
}

.project-links a {
    color: var(--text-light);
    font-style: italic;
    border-bottom: 1px dotted var(--text-light);
    text-decoration: none;
}
.project-links a:hover {
    color: var(--link-color);
    border-bottom-color: var(--link-color);
}

.project-meta-info { /* Style like post-meta */
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem 1rem; /* Match post-meta gap */
    font-size: 0.9rem;
    color: var(--text-light);
    font-style: italic;
    margin-bottom: 0.4rem; /* Match post-header margin for spacing consistency */
}

.project-date {}

.project-tags {
  display: flex;
  gap: 0.75rem;
}

.project-tag {}

.project-description { /* Style like post-description */
  padding-left: 0;
  font-size: 1rem;
  line-height: 1.7;
  font-weight: 300;
  color: var(--text-medium); 
}

/* Responsive adjustments */
@media (max-width: 900px) {
  .container {
    max-width: 90%;
    padding: 2rem 1.25rem 4rem;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 1.5rem 1rem 3rem;
  }
  header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding: 1.25rem;
  }
  nav {
    width: 100%;
    justify-content: space-around; /* Space out nav items */
    margin-top: 0.5rem;
  }
  .content {
    padding: 1.75rem;
  }
  .site-title, .page-title {
    font-size: 1.8rem;
  }
  .project-title { 
      font-size: 1.2rem; /* Match post-title responsive */
  } 
}

@media (max-width: 480px) {
  .container {
    padding: 1.25rem 0.75rem 3rem;
  }
  .content {
    padding: 1.5rem 1.25rem;
  }
  .site-title, .page-title {
    font-size: 1.6rem;
  }
  .project-header {
      flex-direction: column; 
      align-items: flex-start;
      margin-bottom: 0.2rem; /* Adjust spacing when stacked */
  }
  .project-title {
    font-size: 1.2rem; /* Match post-title responsive */
    margin-bottom: 0.4rem; /* Adjust spacing when stacked */
  }
  .project-links {
      width: 100%; 
      justify-content: flex-start; 
      margin-top: 0.2rem; /* Add space when stacked */
  }
  .project-meta-info {
      margin-bottom: 0.6rem; /* Adjust spacing */
  }
  nav a { font-size: 0.95rem; }
}

/* REMOVE extraneous blog styles below this line */
/* .blog-content { ... } */
/* .blog-title { ... } */
/* .blog-post { ... } */
/* ... etc ... */
  