/* Custom slider styles with enhanced visual design */
.difficulty-slider {
  -webkit-appearance: none;
  appearance: none;
  height: 8px;
  border-radius: 8px;
  outline: none;
  transition: all 0.2s ease;
  background-color: #e7e5e4; /* stone-200 */
}

/* Chrome, Safari, Edge styles */
.difficulty-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #44403c; /* stone-700 */
  cursor: pointer;
  border: 3px solid white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  margin-top: -6px;
  transition: all 0.15s ease;
}

.difficulty-slider::-webkit-slider-thumb:hover {
  background: #292524; /* stone-800 */
  transform: scale(1.1);
}

.difficulty-slider::-webkit-slider-thumb:active {
  transform: scale(0.95);
}

/* Firefox styles */
.difficulty-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #44403c; /* stone-700 */
  cursor: pointer;
  border: 3px solid white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.15s ease;
}

.difficulty-slider::-moz-range-thumb:hover {
  background: #292524; /* stone-800 */
  transform: scale(1.1);
}

.difficulty-slider::-moz-range-thumb:active {
  transform: scale(0.95);
}

/* Track styles for Firefox */
.difficulty-slider::-moz-range-track {
  height: 8px;
  border-radius: 8px;
  background-color: #e7e5e4; /* stone-200 */
}

/* Focus states */
.difficulty-slider:focus {
  outline: none;
}

.difficulty-slider:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 4px rgba(68, 64, 60, 0.25);
}

.difficulty-slider:focus::-moz-range-thumb {
  box-shadow: 0 0 0 4px rgba(68, 64, 60, 0.25);
}

/* Disable default outline in IE */
.difficulty-slider::-ms-track {
  width: 100%;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

/* Badge styles */
.difficulty-badge {
  background-color: #e7e5e4;
  transition: all 0.2s ease;
}

.difficulty-badge:hover {
  background-color: #d6d3d1;
}