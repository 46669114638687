/* Styles for the 404 page content */

.error-state.not-found-content {
    text-align: center;
    padding: 4rem 1rem; /* More padding for emphasis */
    font-size: 1.1rem;
    color: var(--text-medium);
    font-style: normal; /* Override generic error-state italic */
}

.error-state.not-found-content h1 {
    font-family: "Playfair Display", serif;
    font-size: 1.8rem;
    color: var(--sea-blue);
    margin-bottom: 1rem;
    font-style: normal; /* Ensure heading is not italic */
}

.error-state.not-found-content p {
    color: var(--text-medium);
    font-style: normal; /* Ensure paragraph is not italic */
} 