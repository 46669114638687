@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;600&display=swap');

body, html {
  margin: 0;
  padding: 0;
  font-family: 'EB Garamond', serif;
}

.container {
  max-width: 2xl;
  margin: 0 auto;
}

/* Navigation styles */
.nav-link {
  @apply hover:underline;
}

/* Section styles */
.section {
  @apply space-y-6 sm:space-y-8;
}

.section-title {
  @apply text-xl sm:text-2xl;
}

/* Work item styles */
.work-item {
  @apply space-y-1 sm:space-y-2;
}

.work-title {
  @apply text-lg sm:text-xl;
}

.work-description {
  @apply text-stone-600 text-base sm:text-lg;
}

/* Writing styles */
.article {
  @apply space-y-1 sm:space-y-2;
}

.article-title {
  @apply text-lg sm:text-xl hover:underline;
}

.article-description {
  @apply text-stone-600 text-base sm:text-lg;
}

/* Contact styles */
.contact-link {
  @apply text-stone-600 hover:text-stone-800 transition-colors;
}