/* Specific styles for Home page */
:root {
  --sea-blue: #3a6d82; /* Slightly softer blue */
  --text-dark: #2f4858;
  --text-medium: #556f7a;
  --text-light: #849aa3;
  --paper-color: rgba(248, 246, 240, 0.9); /* Slightly warmer, more opaque */
  --divider-color: rgba(0, 0, 0, 0.07);
  --link-color: #3a6d82; 
  --soft-green-accent: #a2bb8c;
}

/* Component-specific styles - :root variables should ideally be in index.css */
/* but keeping them here for now as per the previous structure */

.container {
  max-width: 800px; /* Wider container */
  margin: 0 auto; /* Centered */
  padding: 1.5rem 1.5rem 3rem; /* Reduced top/bottom padding slightly */
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.5rem; /* Slightly more padding */
  border-radius: 0.75rem; /* Slightly more rounded */
  background-color: var(--paper-color);
  backdrop-filter: blur(12px); /* More blur */
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.06); /* Softer shadow */
  margin-bottom: 1.5rem; /* Reduced margin */
}

.site-title {
  font-family: "Playfair Display", serif;
  font-size: 2rem; /* Slightly larger */
  font-weight: 500;
  color: var(--sea-blue);
  letter-spacing: -0.01em;
}

.site-title a {
    color: var(--sea-blue);
    text-decoration: none;
    border-bottom: none;
}

.site-title a:hover {
    opacity: 0.9;
    border-bottom: none;
}

nav {
  display: flex;
  gap: 2rem; /* More space */
}

nav a {
  color: var(--text-medium);
  text-decoration: none;
  font-weight: 400;
  font-size: 1rem;
  transition: color 0.2s ease;
  padding-bottom: 2px;
  border-bottom: 1px solid transparent;
}

nav a:hover {
  color: var(--sea-blue);
  border-bottom-color: var(--sea-blue);
}

.content {
  background-color: var(--paper-color);
  border-radius: 0.75rem;
  backdrop-filter: blur(12px);
  padding: 1.5rem 1.75rem 1.75rem; /* Reduced padding slightly */
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.07);
}

section {
  margin-bottom: 1.5rem; /* Reduced margin */
}

section#contact { /* Target contact section specifically */
    margin-bottom: 0;
    padding-top: 1rem; /* Adjusted padding */
    border-top: 1px solid var(--divider-color); /* Optional: separator */
}

section:last-of-type:not(#contact) { /* Ensure only non-contact last section has no margin */
  margin-bottom: 0; 
}


.intro {
  font-size: 1.1rem;
  line-height: 1.7;
  margin-bottom: 1.5rem; /* Reduced margin */
  color: var(--text-dark);
  font-weight: 300;
}

h2 {
  font-family: "Playfair Display", serif;
  font-size: 1.65rem; /* Slightly smaller */
  font-weight: 500;
  color: var(--sea-blue);
  margin-bottom: 1rem; /* Reduced margin */
  padding-bottom: 0.4rem; /* Adjusted padding */
  border-bottom: 1px solid var(--divider-color);
  letter-spacing: -0.01em;
}


/* Entry/Post list items */
.entry, .post {
  margin-bottom: 1.25rem; /* Reduced margin */
  padding-bottom: 1.25rem; /* Reduced padding */
  border-bottom: 1px solid var(--divider-color);
}

.entry:last-child, .post:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.entry-title, .post-title {
  font-family: "Playfair Display", serif;
  font-size: 1.15rem; /* Slightly reduced size for standardization */
  font-weight: 400;
  color: var(--text-dark);
  margin-bottom: 0.3rem;
}

.entry-title a, .post-title a {
    color: var(--text-dark);
    border-bottom: none;
}

.entry-title a:hover, .post-title a:hover {
    color: var(--link-color);
    border-bottom: none;
}

.entry-description, .post-description {
  padding-left: 0;
  font-size: 1rem; /* Descriptions are 1rem */
  line-height: 1.65; /* Slightly tighter */
  font-weight: 300;
}

/* Meta info styling */
.post-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 0.4rem;
  gap: 0.5rem 1rem; 
}

.post-meta {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 0.5rem 1rem;
    font-size: 0.9rem;
    color: var(--text-light);
    font-style: italic;
}


.post-date {}

.post-tags {
  display: flex;
  gap: 0.75rem;
}

.post-tag {
  color: var(--text-light);
}


/* Contact links */
.contact-links {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 0.5rem; /* Added slight top margin */
}

.contact-links a,
.contact-links button {
  font-family: "Playfair Display", serif;
  font-size: 1rem;
  font-weight: 400;
  color: var(--text-medium);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: all 0.2s;
}

.contact-links a:hover,
.contact-links button:hover {
  color: var(--link-color);
  border-bottom-color: var(--link-color);
}

/* Quote */
.quote-section {
  margin: 2.5rem auto 1rem; /* Reduced top margin */
  text-align: center;
  position: relative;
  padding: 0 1.5rem;
  max-width: 650px; /* Constrain quote width */
}

.quote-section:before {
  content: '"';
  position: absolute;
  top: -1.5rem; /* Adjusted */
  left: 50%;
  transform: translateX(-50%);
  font-family: "Playfair Display", serif;
  font-size: 3.5rem;
  color: rgba(0, 0, 0, 0.08);
  line-height: 1;
}

.quote {
  font-family: "Playfair Display", serif;
  font-style: italic;
  font-size: 1.15rem;
  color: var(--text-medium);
  line-height: 1.7;
  max-width: 100%;
  margin: 0 auto;
}

.attribution {
  font-family: "Inter", sans-serif;
  font-size: 0.9rem;
  color: var(--text-light);
  margin-top: 0.75rem;
  font-weight: 300;
}

/* Responsive adjustments */
@media (max-width: 900px) {
  .container {
    max-width: 90%;
    padding: 1.75rem 1.25rem 3.5rem; /* Adjusted */
  }
}

@media (max-width: 768px) {
  .container {
    padding: 1.5rem 1rem 3rem;
  }
  header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding: 1.25rem;
    margin-bottom: 1.5rem; /* Adjusted */
  }
  nav {
    width: 100%;
    justify-content: space-around; /* Space out nav items */
    margin-top: 0.5rem;
  }
  .content {
    padding: 1.75rem;
  }
  .site-title {
    font-size: 1.8rem;
  }
  h2 {
      font-size: 1.5rem; /* Adjusted */
  }
}

@media (max-width: 480px) {
  .container {
    padding: 1.25rem 0.75rem 3rem;
  }
  .content {
    padding: 1.5rem 1.25rem;
  }
  .site-title {
    font-size: 1.6rem;
  }
  h2 {
      font-size: 1.4rem; /* Adjusted */
  }
  .entry-title, .post-title {
    font-size: 1.15rem; /* Adjusted */
  }
  nav a { font-size: 0.95rem; }
} 