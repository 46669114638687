/* Replace all existing content with these styles */
:root {
  --sea-blue: #1f5771;
  --text-dark: #263c40;
  --text-medium: #465a61;
  --text-light: #78898f;
  --paper-color: rgba(245, 243, 236, 0.88);
  --divider-color: rgba(0, 0, 0, 0.06);
  --link-color: #24677f;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  color: var(--text-medium);
  background-color: #79b7d3;
  background-image: url("/public/ghibli.png");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  line-height: 1.55;
  min-height: 100vh;
  overflow-x: hidden;
}

/* Subtle vignette effect */
body:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0) 70%,
    rgba(0, 0, 0, 0.15) 100%
  );
  pointer-events: none;
  z-index: -1; /* Ensure it's behind content */
}

/* Basic link styling */
a {
  color: var(--link-color);
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: all 0.2s;
}

a:hover {
  opacity: 1;
  border-bottom-color: var(--link-color);
}